import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import Menu from '../components/Menu/Menu';
import { getDashboardDatas } from '../libs/api';

function DashboardPage() {
    const [selectedAccount] = useState<string | null>(localStorage.getItem('selectedAccount'));
    const [data, setData] = useState<any | null>(null);

    const fetchDashboardDatas = async (ac: string) => {
        const datas = await getDashboardDatas(ac);
        console.log(datas)
        setData(datas)
    }

    useEffect(() => {
        if (selectedAccount) {
            fetchDashboardDatas(selectedAccount);
        }
    }, [selectedAccount]);

    return (
      <div className='container'>
        <Menu></Menu>
            <div className="main-content">
            <div className="dashboard">
                <h1>Performance Dashboard</h1>
                    {data && 
                        <>
                        <div className="stats-cards">
                            <div className="stat-card">
                                <h3>Total Calls Finished</h3>
                                <p>{data.totalCallsFinished}</p>
                            </div>
                            <div className="stat-card">
                                <h3>Total Calls to Make</h3>
                                <p>{data.totalCallsNotStarted}</p>
                            </div>
                            <div className="stat-card">
                                <h3>Converted Carts</h3>
                                <p>{data.convertedCarts}</p>
                            </div>
                            <div className="stat-card">
                                <h3>Revenue Generated</h3>
                                <p>{data.revenueGenerated.toFixed(2)} {data.account.shop_currency}</p>
                            </div>
                        </div>

                        <div className="detailed-stats">
                            <div className="chart">
                                <h3>Call Feedback Analysis</h3>
                                <ul>
                                    <li>Positive: {data.feedbackStats.positive}</li>
                                    <li>Neutral: {data.feedbackStats.neutral}</li>
                                    <li>Negative: {data.feedbackStats.negative}</li>
                                </ul>
                                <h3>Informations</h3>
                                <ul>
                                    <li>Discount given: {data.callsWithDiscountGiven}</li>
                                    <li>Call with Voicemail: {data.callsVoicemail}</li>
                                    <li>Call with Conversation: {data.totalCallsFinished - data.callsVoicemail}</li>
                                </ul>
                            </div>
                            <div className="chart">
                                <h3>Recent Calls</h3>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Customer</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Feedback</th>
                                        <th>Voicemail</th>
                                        <th>Discount given</th>
                                        <th>Duration</th>
                                        <th>Cart Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.recentCalls.map((call:any) => (
                                        <tr key={call.id}>
                                        <td>{call.customerName}</td>
                                        <td>{call.status}</td>
                                        <td>{call.date}</td>
                                        <td>{call.feedback}</td>
                                        <td>{call.voicemail ? 'Voicemail' : 'Conversation'}</td>
                                        <td>{call.discountGiven ? 'Yes' : 'No'}</td>
                                        <td>{call.duration}</td>
                                        <td>{call.cartValue}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </>
                }
            </div>
        </div>
    </div>
  );
}

export default DashboardPage;
